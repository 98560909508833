/* line 39, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-criteria-breadcrumb .breadcrumb__item {
	margin-right: 10px;
	margin-bottom: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-criteria-breadcrumb .breadcrumb__item {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .c-criteria-breadcrumb .breadcrumb__item {
		margin-right: 0;
	}
}

/* line 44, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-criteria-breadcrumb .breadcrumb__label {
	display: inline-block;
	padding: 2.5px 5px;
	border-width: 1px 0 1px 1px;
	border-style: solid;
	border-color: rgb(var(--color-global-border));
	margin-bottom: 5px;
}

/* line 52, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-criteria-breadcrumb .breadcrumb__label:last-child {
	border-width: 1px;
}

/* line 56, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-criteria-breadcrumb .breadcrumb__label--primary {
	background-color: rgb(var(--color-global-widget));
	color: rgb(var(--color-global-primary));
	font-weight: 600;
	text-transform: uppercase;
}

/* line 63, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-criteria-breadcrumb .breadcrumb__label--secondary {
	color: rgb(var(--color-global-highlight));
	text-transform: capitalize;
}

/* line 75, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-breadcrumb .breadcrumb__item {
	color: rgb(var(--color-global-highlight));
}

/* line 98, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-breadcrumb .breadcrumb__item--disabled {
	color: rgb(var(--color-global-text));
}

/* line 103, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-breadcrumb .breadcrumb__item:not(.breadcrumb__item--disabled):hover {
	color: rgb(var(--color-global-secondary));
}

/* line 109, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-breadcrumb .breadcrumb__separator {
	border-left: 3px solid rgb(var(--color-global-text));
	border-top: 3px solid transparent;
	border-bottom: 3px solid transparent;
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 5px;
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .c-breadcrumb .breadcrumb__separator {
	border-left: 0;
	border-right: 3px solid rgb(var(--color-global-text));
}

/* line 123, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.localguide__above .c-breadcrumb .breadcrumb__separator::before, .localguide__above .c-breadcrumb .breadcrumb__item {
	color: rgb(var(--color-global-positive));
	text-shadow: none;
}

/* line 129, scss/80-themes/Saunders/60-components/breadcrumb.scss */
.c-breadcrumb .breadcrumb__wrapper {
	white-space: nowrap;
	display: inline-block;
}

/*# sourceMappingURL=../../../../true */